import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Hello! Welcome to my webiste!
          This site is currently under development. If you would like to contact me, send me an email at <a href="mailto: contact@matheisl.co.uk"><code>contact@matheisl.co.uk</code></a></p>
        <p>
          Alternatively, here are my social media links:
        </p>
            <ul>
              <li><a href="https://github.com/aubergine5678">Github</a></li>
              <li><a href="https://twitter.com/markmatheisl">Twitter</a></li>

              <li><a href="https://www.instagram.com/mark.m.music/">Instagram</a></li>
              <li><a href="mailto:contact@matheisl.co.uk">E-Mail</a></li>
            </ul>
      </header>
    </div>
  );
}

export default App;
